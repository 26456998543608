import React from 'react';
import c from './form.module.scss';
import { Close } from '../svg';
import { serviceTel, serviceTelHref } from '../../consts';
import Button from '../button';

const ModalForm = ({ title, subtitle, variant, onClose }) => {
  const firstInputProps = {
    name: variant === "primary" ? "name" : "vin",
    id: variant === "primary" ? "name" : "vin",
    placeholder: variant === "primary" ? "Ваше ім’я" : "Vin номер",
  };

  return (
    <>
      <div onClick={onClose} className={c.overlay} />
      <div className={c.modalContainer}>
        <form method="POST" id="form" className={c.modalForm}>
          <div onClick={onClose} className={c.close}>
            <Close />
          </div>
          {title && <h2 className={c.title}>{title}</h2>}
          {subtitle && <p className={c.subtitle} dangerouslySetInnerHTML={{__html: subtitle}} />}
          {["primary", "tertiary"].includes(variant) && (
            <input
              required
              minLength="2"
              maxLength="20"
              type="text"
              {...firstInputProps}
            />
          )}
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Ваш Номер Телефону"
          />
          {variant !== "secondary" && (
            <input
              name="comment"
              id="comment"
              type="text"
              placeholder={
                variant === "primary" ? "Вид Послуги" : "Що саме цікавить"
              }
            />
          )}
          <div className={c.btns}>
            <Button
              name="send"
              id="submit"
              formType="submit"
              type="secondary"
              text="відправити"
            />
            <Button
              Component="a"
              href={serviceTelHref}
              className={c.serviceTel}
              type="primary"
              text={serviceTel}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalForm;