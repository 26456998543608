import React from 'react';
import cn from 'classnames';
import c from './form.module.scss';
import { serviceTel, serviceTelHref } from '../../consts';
import Button from '../button';

const Form = ({children: topChildren, className}) => {
  return (
    <form method="POST" id="form" className={cn(c.form, className)}>
      {topChildren}
      <input
        required
        minLength="2"
        maxLength="20"
        name="name"
        id="name"
        type="text"
        placeholder="Ваше ім’я"
      />
      <input
        type="tel"
        name="phone"
        id="phone"
        placeholder="Ваш Номер Телефону"
      />
      <input
        name="comment"
        id="comment"
        type="text"
        placeholder="Вид Послуги"
      />
      <div className={c.btns}>
        <Button
          name="send"
          id="submit"
          formType="submit"
          type="secondary"
          text="відправити"
        />
        <Button
          Component="a"
          href={serviceTelHref}
          className={c.serviceTel}
          type="primary"
          text={serviceTel}
        />
      </div>
    </form>
  );
};

export default Form;