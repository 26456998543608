import React, { useState, useEffect } from 'react';
import Button from '../button';
import cn from 'classnames';
import { DiagnosticIcon, ComputerIcon, OilIcon } from '../svg';
import c from './sale.module.scss';

const expirationDate = '2025-02-01T00:00:00';

function getDateDifference(date2) {
  const date1 = new Date();

  const msDifference = Math.abs(new Date(date2) - date1);

  const days = Math.floor(msDifference / (1000 * 60 * 60 * 24));

  const msRemaining = msDifference % (1000 * 60 * 60 * 24);
  const hours = Math.floor(msRemaining / (1000 * 60 * 60)).toString().padStart(2, '0');
  const minutes = Math.floor((msRemaining % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
  const seconds = Math.floor((msRemaining % (1000 * 60)) / 1000).toString().padStart(2, '0');

  return {
    days,
    hours,
    minutes,
    seconds
  };
}

const Sale = () => {
  const [timeLeft, setTimeLeft] = useState(getDateDifference(expirationDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getDateDifference(expirationDate));
    }, 1000);

    return () => clearInterval(timer)
  }, []);

  const saleData = [
    {title: "діагностика ходової", Icon: DiagnosticIcon},
    {title: "комп’ютерна діагностика", Icon: ComputerIcon},
    {title: "заміна <br/> масла", Icon: OilIcon},
  ];

  return (
    <div className={c.sale}>
      <h2>
        СПЕЦІАЛЬНО ДО ВІДКРИТТЯ
        <img src="/assets/img/fire.png" alt="fire" />
      </h2>
      <div className={c.block}>
        <div className={c.left}>
          <p className={c.title}>
            Безкоштовно
            <span>Лише одна опція</span>
          </p>
          <div className={c.list}>
            {saleData.map(({ title, Icon }) => {
              return (
                <div className={c.item}>
                  <div className={c.round}>
                    <Icon />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: title }}
                    className={c.sale_title}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className={c.right}>
        <div className={c.count_desktop}>
          <h4>
            пропозиція обмежена<span>!</span>
          </h4>
          <div className={c.countdown}>
            <div style={{paddingLeft: '8px'}}>
              <p>Днів</p>
              <p className={c.number}>
                {timeLeft.days}
              </p>
            </div>
            <div>
              <p style={{paddingLeft: '8px'}}>секунд</p>
              <p className={cn(c.number, c.full)}>
                {`${timeLeft.hours}`}
                <span>:</span>
                {`${timeLeft.minutes}`}
                <span>:</span>
                {`${timeLeft.seconds}`}
              </p>
            </div>
          </div>
        </div>
        <div className={c.count_mobile}>
          <p className={c.title}>Пропозиція обмежена!</p>
          <div className={c.countdown}>
            <div className={c.section}>
              <p className={c.number}>{timeLeft.days}</p>
              <p className={c.date}>Днів</p>
            </div>
            <div className={c.section}>
              <p className={c.number}>
                {timeLeft.hours}
                <span>:</span>
                {timeLeft.minutes}
              </p>
              <p className={c.date}>Хвилин</p>
            </div>
            <div className={c.section}>
              <p className={c.number}>{timeLeft.seconds}</p>
              <p className={c.date}>Секунд</p>
            </div>
          </div>
        </div>
        <Button className={c.send} type="secondary" text="записатись" />
        </div>
      </div>
      <img loading='lazy' src="/assets/img/sale_car.png" alt="car" />
    </div>
  );
}

export default Sale;