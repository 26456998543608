import React, { useState, useEffect } from 'react';
import { Arrow } from '../svg';
import ModalForm from '../form/modal-form';
import cn from 'classnames';
import { Checkmark } from '../svg';
import c from './hero.module.scss';

const Hero = () => {
  const [modalData, setModalData] = useState(null);

  const serviceData = [
    {
      title: "ПІДБІР ЗАПЧАСТИН",
      className: c.active,
      modalData: {
        variant: 'tertiary',
        title: 'ПІДБІР ЗАПЧАСТИН'
      }
    },
    {
      title: "Запис на СТО",
      className: c.inactive,
      modalData: {
        variant: 'secondary',
        title: 'Запис на СТО'
      },
    },
    {
      title: "Консультація",
      className: c.inactive,
      modalData: {
        variant: 'primary',
        title: 'Консультація',
        subtitle: 'і ми <span>перетелефонуємо</span> протягом <span>10 хв</span>'
      },
      modalVariant: 'primary'
    }
  ];

  const onModalClose = () => {
    setModalData(null);
  }


  useEffect(() => {
    if (modalData) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalData])

  return (
    <>
      {modalData && (
        <ModalForm onClose={onModalClose} {...modalData} />
      )}
      <div className={c.hero}>
        <h1>сто повного циклу</h1>
        <div className={c.container}>
          <div className={c.left}>
            <p className={c.title}>
              Зручний і простий спосіб тримати ваш автомобіль в хорошому стані
            </p>
            <div className={c.info}>
              <p>
                <Checkmark />
                Запчастини в наявності та на замовлення
              </p>
              <p>
                <Checkmark />
                Ремонт
              </p>
              <p>
                <Checkmark />
                Обслуговування
              </p>
            </div>
          </div>
          <div className={c.services}>
            {serviceData.map(({ title, className, modalData }) => (
              <p onClick={() => setModalData(modalData)} key={title} className={cn(className, c.info_item)}>
                <span>{title}</span>
                <p className={c.arrow}>
                  <Arrow />
                </p>
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;